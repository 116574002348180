import React from "react"
import { graphql} from 'gatsby'
import Layout from "../components/layout"
import { Container, Row, Col } from 'reactstrap'
import Breadcrumb  from "../components/breadcrumb"
import useSiteMetadata from '../hooks/use-site-config'
import { Helmet } from "react-helmet"

export const query = graphql`
{
  allFile(filter: {dir: {regex: "/list_pharmacy_2020/"}}) {
    edges {
      node {
        publicURL
        name
      }
    }
  }
}
`

// formato para poner el nombre del archivo de las farmacias= Nombre_el resto.
//se usa el nombre antes del guion para mostrar en la pagina.
export default function Pharmacy(props) {

  const { siteTitle } = useSiteMetadata()

  const pharmaList = props.data.allFile;
  const title="Lista de Farmacias de turno | "+ siteTitle
  return (
    <Layout >
       <Helmet title={title}>
          <meta charSet="utf-8" />
        </Helmet>
      <Container>
        <h1 className="text-center m-4">Lista de Farmacias de turno</h1>
        <Row className="justify-content-center">
          <Col sm="12" md={{ size: 8 }}>
          <ul>
        {pharmaList.edges.map((file, index) => {
          return (
            <li key={`file-${index}`}>
              <a href={file.node.publicURL} download>
                {file.node.name.split("_")[0]}
              </a>
            </li>
          )
        })}
      </ul>
          </Col>
        </Row>
      </Container>
     
      <Breadcrumb pageContext={props.pageContext} label="Farmacias"/>

    </Layout>
  )
}
